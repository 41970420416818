a {
  text-decoration: none;
  color: inherit;
}

.rdrMonth {
  width: 100% !important;
}


.custom-button {
  cursor: pointer !important;
  padding: 6px !important;
}

.modal {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 75%;
  height: 50%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

/* .modal-header {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
} */

/* .modal-footer {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
} */

.display-block {
  display: block;
}

.display-none {
  display: none;
}
